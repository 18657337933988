
import React, { forwardRef, useMemo, useState } from 'react';
import { Box, Button, Center, Radio, RadioGroup, Spinner, Stack, Text, Textarea, Input } from '@chakra-ui/react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import getDay from 'date-fns/getDay'
import setHours from 'date-fns/setHours'
import setMinutes from 'date-fns/setMinutes'
import format from 'date-fns/format'
import range from 'lodash/range';
import useSWR from 'swr'

import BackgroundImage from '../components/BackgroundImage';
import { responsive } from '../contexts/responsive';
import up from './Thank//up.png'
import down from './Thank/down.png'
import arrow from './arrow_down.png'
import useOrderData from '../hooks/useOrderData';

const SHEET_API = 'https://script.google.com/a/macros/relab.cc/s/AKfycbz-3ki-lOqMoadOIJeDVZc8jb_R2SJY-PTEQ48OoylTk3h3QW7Ld4XLTmTyIvK8_jZU5w/exec'

const forms = [
  {
    label: '預約日期*必填',
    key: 'date',
    type: 'date',
    min: '2022-06-22',
    max: '2022-12-30',
    placeholderText: '請選擇日期',
    note: '僅開放週三至週五',
  },
  {
    label: '預約時段*必填',
    key: 'time',
    type: 'select',
    showTimeSelect: true,
    showTimeSelectOnly: true,
    timeIntervals: 60,
    timeCaption: "Time",
    dateFormat: "h:mm aa",
    placeholderText: '請選擇時段',
    note: '每一時段50分鐘',
    minTime: setHours(setMinutes(new Date(), 0), 9),
    maxTime: setHours(setMinutes(new Date(), 0), 19),
  },
]

const noon = setHours(setMinutes(new Date(), 0), 12)

const CustomInput = forwardRef(({ value, onClick }, ref) => (
  <Box pos="relative">
    <Button
      bg="custom.bgGray"
      _hover={{ bg: 'custom.bgGray' }}
      height="2.5rem"
      justifyContent={'flex-start'}
      px="0.875rem"
      mt="0.625rem"
      borderRadius={'0.625rem'}
      _placeholder={{ color: 'custom.listColor', fontSize: responsive('0.875em', '1.125em') }}
      ref={ref}
      onClick={onClick}
    >{value}</Button>
    <Box pointerEvents={'none'} width="1.5em" pos="absolute" right={'0.625rem'} top="37.5%">
      <BackgroundImage src={arrow} ratio={1} />
    </Box>
  </Box>
))

const fetcher = (...args) => fetch(...args).then((res) => res.json())
  .then(values => values.slice(1).map((value) => ({
    value,
    date: new Date(`${value[0]} ${value[1]}:00`),
  })))

const Book = () => {
  const { data: bookedTime } = useSWR(SHEET_API, fetcher)
  const data = useOrderData()
  const isValidOrder = data && data.line_items.some(({ product_id }) => product_id === 11)
  const hasBooked = useMemo(() => data?.id && bookedTime?.find(d => d.value[2] === data?.id), [bookedTime, data])

  const [books, setbook] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [finish, setSubmit] = useState()
  const [errorMsg, setError] = useState()
  const isWeekday = (date) => {
    const day = getDay(date);
    return [3, 4, 5].includes(day) && date > new Date()
  };
  const filterPassedTime = (time) => {
    return time.getHours() != 12;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { date, time } = books;
    if (date && time) {
      setIsSubmitting(true)
      const formData = new FormData(e.target);
      formData.append('Email', data?.customer_email || 'test@relab.cc');
      formData.append('Date', format(date, 'yyyy-MM-dd'));
      formData.append('Time', format(time, 'HH:mm'));
      formData.append('Order ID', data?.id);
      try {
        const res = await fetch(SHEET_API, {
          method: 'POST',
          body: formData,
        })
        const jsonRes = await res.json()
        if (res.status > 400) {
          if (res.status === 409) {
            setError(`您已經預約了 ${jsonRes.Date} ${jsonRes.Time} 時段，如要更改，請聯絡我們`)
          } else {
            throw new Error(res.status)
          }
        }
        setSubmit(true)
      } catch (e) {

      }
      setIsSubmitting(false)
    }
  }
  return (
    <Box bg="custom.skin" maxW="36em" mx="auto" pos="relative" overflow={'hidden'}>
      <Box pointerEvents={'none'} pos="absolute" top="0" left="0" right="0">
        <BackgroundImage src={up} ratio={750 / 460} />
      </Box>
      <Box pointerEvents={'none'} pos="absolute" bottom="0" left="0" right="0">
        <BackgroundImage src={down} ratio={750 / 280} />
      </Box>
      {true ? (finish || hasBooked) ? (
        <Center textAlign={'center'} flexDirection={'column'} minHeight="100vh" py="1em" pos="relative">
          <Text fontSize={responsive('1.375em', '1.625em')} fontWeight='bold'>預約完成</Text>
          <Text mt="1rem" fontSize={responsive('0.875em', '1.125em')}>
            已預約
            <Text ml="0.5em" as="span" fontWeight="700">
              {hasBooked ? `${hasBooked.value[0]} ${hasBooked.value[1]}` : `${format(books.date, 'yyyy-MM-dd')} ${format(books.time, 'HH:mm')}`}
            </Text>
            <br />
            在您預約的諮詢時間開始之前<br />
            會由專人聯繫您 <br />
            <br />
            若沒有收到通知或有任何疑問<br />
            請聯絡：service@kiddeveloping.com<br />
            專人諮詢：02-2325-5685
          </Text>
        </Center>
      ) : (
        <Center
          py="1em"
          pos="relative"
          flexDirection={'column'}
          minHeight="100vh"
          as="form"
          onSubmit={handleSubmit}
        >
          <Text fontSize={responsive('1.375em', '1.625em')} fontWeight='bold'>預約專家</Text>
          <Stack mt="0.625rem" px="0.875em" spacing={4}>
            {forms.map(({ label, min, max, key, note, ...props }, i) => (
              <Box key={i}>
                <Text fontSize={responsive('1em', '1.125em')}>{label}</Text>
                <DatePicker
                  name={key}
                  selected={books?.[key]}
                  customInput={<CustomInput />}
                  minDate={new Date(min)}
                  maxDate={new Date(max)}
                  filterDate={isWeekday}
                  filterTime={filterPassedTime}
                  onChange={(date) => {
                    books[key] = date
                    setbook({ ...books })
                  }}
                  excludeTimes={[noon].concat(bookedTime?.map(d => d.date))}
                  isRequired
                  {...props}
                />
                <Text fontSize={responsive('0.75rem', '1em')}>({note})</Text>
              </Box>
            ))}
            <RadioGroup
              name="Method"
              onChange={(value) => {
                books.contact = value
                setbook({ ...books })
              }}
              value={books?.contact}
            >
              <Text fontSize={responsive('1em', '1.125em')}>通話方式*必選</Text>
              <Stack my="0.5rem" direction='row' justifyContent={'space-between'}>
                <Radio bg="white" value='skype'>
                  <Text ml="1rem" fontSize={responsive('1.25em')}>Skype</Text>
                </Radio>
                <Radio bg="white" value='phone'>
                  <Text ml="1rem" fontSize={responsive('1.25em')}>國內電話</Text>
                </Radio>
              </Stack>
              <Text fontSize={responsive('0.75rem', '1em')} color="custom.skyBlue">＊會由專人在諮詢開始前聯繫您</Text>
            </RadioGroup>
            <Box fontSize={'0.875em'}>
              <Text fontSize={responsive('1em', '1.125em')}>連絡電話*必填</Text>
              <Input
                name="Phone"
                borderRadius={'0.625rem'}
                placeholder=""
                bg="custom.bgGray"
                py="0.75rem"
                px="0.875rem"
                mt="0.625rem"
                _placeholder={{ color: 'custom.listColor', fontSize: responsive('0.875em', '1.125em') }}
                isRequired
              />
            </Box>
            <Box>
              <Text fontSize={responsive('1em', '1.125em')}>問題</Text>
              <Stack>
                {range(3).map((d) => (
                  <Textarea
                    onChange={(e) => {
                      books[`q-${d + 1}`] = e.target.value
                      setbook({ ...books })
                    }}
                    bg="custom.bgGray"
                    placeholder={`問題${d + 1}：(200字以內)`}
                    minHeight="3em"
                    key={d}
                    name={`Question ${d + 1}`}
                    rows={2}
                  />
                ))}
              </Stack>
            </Box>
          </Stack>
          <Button
            type="submit"
            width={responsive('10rem', '16rem')}
            mt="2em"
            variant={'skyBlue'}
            isLoading={isSubmitting}
            isDisabled={process.env.NODE_ENV === 'production' && !isValidOrder}
          >確認送出</Button>
          {errorMsg && <Text my="2" color="red.500" fontSize="sm">{errorMsg}</Text>}
        </Center>
      ) : (
        <Center minHeight="100vh">
          <Spinner />
        </Center>
      )}
    </Box>
  )
}

export default Book;
